import Card from "card";
import changePaymentKind from "../../src/site/components/gateways/changePaymentKind"
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "creditCardInputField", "addOrderBumpButton", "cvvCard",
    "creditCardInputField", "holder", "number"
  ];

  static values = {
    tokenKey: String,
    testMode: Boolean
  };

  initialize() {
    if ($("#customer_orders_year_validate").val().length == 4) {
      $("#customer_orders_credit_card_validate").val($("#customer_orders_month_validate").val() + "/" + $("#customer_orders_year_validate").val().slice(2, 4))
      $("#card_form_credit_card_validate").val($("#customer_orders_month_validate").val() + "/" + $("#customer_orders_year_validate").val().slice(2, 4))
    }
    this.initializeCreditCardShowFields();
  }

  /**
   * NOTE:
   * replacing the button when clicking to prevent user to spam clicking
   * and add more items
   */
  addOrderBump() {
    this.addOrderBumpButtonTarget.outerHTML = `
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      `;
  }

  initializeCreditCardShowFields() {
    new Card({
      form: ".one_step_payment_form",
      container: ".card-wrapper",

      formSelectors: {
        numberInput: "#customer_orders_credit_card_number",
        nameInput: "#customer_orders_credit_card_titular_name",
        expiryInput: "#customer_orders_credit_card_validate",
        cvcInput: "#customer_orders_credit_card_security_code",
      },

      placeholders: {
        name: "Nome",
      },

      width: 270,
    });
  }

  creditCardOneStepShowFields() {
    $("#paymentKindPix").addClass("d-none");
    $("#paymentKindBankSlip").addClass("d-none");
    $("#paymentKindCreditCart").addClass("show").removeClass("d-none");

    $("#buttonPix").removeClass("active");
    $("#buttonBankSlip").removeClass("active");
    $("#buttonCreditCard").addClass("active");

    new Card({
      form: ".one_step_payment_form",
      container: ".card-wrapper",

      formSelectors: {
        numberInput: "#customer_orders_credit_card_number",
        nameInput: "#customer_orders_credit_card_titular_name",
        expiryInput: "#customer_orders_credit_card_validate",
        cvcInput: "#customer_orders_credit_card_security_code",
      },

      placeholders: {
        name: "Nome",
      },

      width: 270,
    });
  }

  changePaymentPix() {
    const $paymentSelect = $("#paymentTypePix");
    disablePaymentKindSelect($("#paymentTypeCreditCard"));
    disablePaymentKindSelect($("#paymentTypeBankSlip"));
    $paymentSelect.addClass("payment_selected");
    $('.pix_payment_kind').prop('checked', true);
    $.ajax({
      url: `/payments/change_payment_kind?payment_kind=pix`,
      type: "GET",
      dataType: "script",
    }).done(function () {
      $(".next").removeClass("disabled");
      $("#finalize_order_button").removeClass("disabled");
    });
  }

  changePaymentCreditCard() {
    const $paymentSelect = $("#paymentTypeCreditCard");
    disablePaymentKindSelect($("#paymentTypePix"));
    disablePaymentKindSelect($("#paymentTypeBankSlip"));
    $paymentSelect.addClass("payment_selected");
  }

  changePaymentBankSlip() {
    const $paymentSelect = $("#paymentTypeBankSlip");
    disablePaymentKindSelect($("#paymentTypeCreditCard"));
    disablePaymentKindSelect($("#paymentTypePix"));
    $paymentSelect.addClass("payment_selected");
    $('.bank_slip_payment_kind').prop('checked', true)
  }

  splitFieldsCreditCard() {
    const expDateValidation = this.creditCardInputFieldTarget.value;
    const [month, year] = expDateValidation.split("/");
    const formattedYear = `20${year}`;

    $("#customer_orders_month_validate").val(month);
    $("#customer_orders_year_validate").val(formattedYear);
    $('.credit_card_payment_kind').prop('checked', true);
  }

  async generateToken(event) {
    this.GatewayTokenize(event)
  }

  GatewayTokenize(event) {
    const gatewayName = $("#gateway-name-tokenize").val()
    if (gatewayName) {
      this[gatewayName + 'Tokenize'](event)
    }
  }


  TryploPayTokenize(event) {
    if (this.numberTarget.value != "" &&
      this.holderTarget.value != "" &&
      this.cvvCardTarget.value != "") {
      event.preventDefault();
      TryploPay.setAccountID(this.tokenKeyValue);
      let [customerFirstName, ...customerLastName] = this.holderTarget.value.split(" ");
      customerLastName = customerLastName.join(' ')

      let cc = TryploPay.CreditCard(
        ($("#customer_orders_credit_card_number").val() || $("#credit_card_number").val()).replaceAll(" ", ''),
        $("#customer_orders_month_validate").val(),
        $("#customer_orders_year_validate").val(),
        customerFirstName,
        customerLastName,
        ($("#customer_orders_credit_card_security_code").val() || $("#credit_card_security_code").val())
      );
      let tokenResponseHandler = function (data) {
        let token;
        if (data.errors) {
          //console.log("Erro salvando cartão: " + JSON.stringify(data.errors));
        } else {
          token = data.id;
          if (token) {
            $("#credit_card_token").val(token);
            $("#finalize_order_button_tokenize").prop('disabled', false)
          }
        }
      }
      TryploPay.createPaymentToken(cc, tokenResponseHandler);
      TryploPay.setTestMode(this.testModeValue);
    } else {
      $("#finalize_order_button_tokenize").prop('disabled', true)
    }
  }

  DomPagamentosV3Tokenize(event) {
    if (this.numberTarget.value != "" &&
      this.holderTarget.value != "" &&
      this.cvvCardTarget.value.length > 2) {
      event.preventDefault();
      let public_key = this.tokenKeyValue;
      let data_card = {
        name: this.holderTarget.value,
        document: $(".customer_cpf_input").val().replaceAll('.', '').replaceAll('-', ''),
        number: ($("#customer_orders_credit_card_number").val() || $("#credit_card_number").val()).replaceAll(" ", ''),
        cvv: ($("#customer_orders_credit_card_security_code").val() || $("#credit_card_security_code").val()),
        month: $("#customer_orders_month_validate").val(),
        year: $("#customer_orders_year_validate").val()
      }

      let installments = parseInt($("#customer_orders_payment_installments").val());

      getTokenCard(public_key, data_card, installments, function (data) {
        $("#credit_card_token").val(JSON.stringify(data));
        $("#finalize_order_button_tokenize").prop('disabled', false)
      });

    } else {
      $("#finalize_order_button_tokenize").prop('disabled', true)
    }
  }

  MaxpayV2Tokenize(event) {
    $("#finalize_order_button_tokenize").prop('disabled', true)
    if (this.numberTarget.value != "" &&
      this.holderTarget.value != "" &&
      this.cvvCardTarget.value.length > 2) {

      setTimeout(async () => {

        HydraPay.setPublicKey(this.tokenKeyValue)

        var token = await HydraPay.encrypt({
          number: ($("#customer_orders_credit_card_number").val() || $("#credit_card_number").val()).replaceAll(" ", ''),
          holderName: this.holderTarget.value,
          expMonth: $("#customer_orders_month_validate").val(),
          expYear: $("#customer_orders_year_validate").val(),
          cvv: ($("#customer_orders_credit_card_security_code").val() || $("#credit_card_security_code").val())
        })

        $("#credit_card_token").val(token)
        if (token) {
          $("#finalize_order_button_tokenize").prop('disabled', false)
        }
      }, 300);
    }
  }
}

function disablePaymentKindSelect(paymentSelect) {
  paymentSelect.removeClass("payment_selected");
}
