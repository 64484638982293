import Card from "card";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.initializeCardAnimateShowFields();
    if ($("#yearValidate").val().length == 4) {
      $("#card_form_credit_card_validate").val($("#monthValidate").val() + "/" + $("#yearValidate").val().slice(2, 4))
    }
  }
  static targets = ["creditCardInputField",
    "addOrderBumpButton", "expMonth", "expYear",
    "cvvCard", "creditCardInputField", "holderName", "number"];

  static values = {
    tokenKey: String,
    testMode: Boolean
  };

  initializeCardAnimateShowFields() {
    new Card({
      form: "#new_card_form",
      container: ".card-wrapper",

      formSelectors: {
        numberInput: "#card_form_credit_card_number",
        nameInput: "#card_form_credit_card_titular_name",
        expiryInput: "#card_form_credit_card_validate",
        cvcInput: "#card_form_credit_card_security_code",
      },

      placeholders: {
        name: "Nome",
      },

      width: 270,
    });
  }

  addOrderBump() {
    this.addOrderBumpButtonTarget.outerHTML = `
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      `;
  }

  creditCardShowField() {
    $("#paymentKindPix").addClass("d-none");
    $("#paymentKindBankSlip").addClass("d-none");
    $("#paymentKindCreditCart").addClass("show").removeClass("d-none");

    $("#buttonPix").removeClass("active");
    $("#buttonBankSlip").removeClass("active");
    $("#buttonCreditCard").addClass("active");

    new Card({
      form: "#new_card_form",
      container: ".card-wrapper",

      formSelectors: {
        numberInput: "#card_form_credit_card_number",
        nameInput: "#card_form_credit_card_titular_name",
        expiryInput: "#card_form_credit_card_validate",
        cvcInput: "#card_form_credit_card_security_code",
      },

      placeholders: {
        name: "Nome",
      },

      width: 270,
    });
  }

  bankSlipShowField() {
    $("#paymentKindPix").addClass("d-none");
    $("#paymentKindCreditCart").addClass("d-none");
    $("#paymentKindBankSlip").addClass("show").removeClass("d-none");

    $("#buttonPix").removeClass("active");
    $("#buttonBankSlip").addClass("active");
    $("#buttonCreditCard").removeClass("active");
  }

  pixShowField() {
    $("#paymentKindCreditCart").addClass("d-none");
    $("#paymentKindBankSlip").addClass("d-none");
    $("#paymentKindPix").addClass("show").removeClass("d-none");

    $("#buttonPix").addClass("active");
    $("#buttonBankSlip").removeClass("active");
    $("#buttonCreditCard").removeClass("active");
  }
  splitFieldsCreditCard() {
    const expDateValidation = this.creditCardInputFieldTarget.value;
    const [month, year] = expDateValidation.split("/");
    const formattedYear = `20${year}`;

    $("#monthValidate").val(month);
    $("#yearValidate").val(formattedYear);
  }

  changePaymentBankSlip() {
    const $paymentSelect = $("#paymentTypeBankSlip");
    disablePaymentKindSelect($("#paymentTypeCreditCard"));
    disablePaymentKindSelect($("#paymentTypePix"));
    $paymentSelect.addClass("payment_selected");
  }

  changePaymentPix() {
    const $paymentSelect = $("#paymentTypePix");
    disablePaymentKindSelect($("#paymentTypeCreditCard"));
    disablePaymentKindSelect($("#paymentTypeBankSlip"));
    $paymentSelect.addClass("payment_selected");
  }

  changePaymentCreditCard() {
    const $paymentSelect = $("#paymentTypeCreditCard");
    disablePaymentKindSelect($("#paymentTypePix"));
    disablePaymentKindSelect($("#paymentTypeBankSlip"));
    $paymentSelect.addClass("payment_selected");
  }

  async generateToken(event) {
    const gatewayName = $("#gateway-name-tokenize").val() + 'Tokenize'
    this[gatewayName](event)
  }


  TryploPayTokenize(event) {
    if (this.numberTarget.value != "" &&
      this.holderNameTarget.value != "" &&
      this.cvvCardTarget.value != "") {
      event.preventDefault();
      TryploPay.setAccountID(this.tokenKeyValue);
      let [customerFirstName, ...customerLastName] = $("#card_form_credit_card_titular_name").val().split(" ");
      customerLastName = customerLastName.join(' ')
      let cc = TryploPay.CreditCard(
        $("#card_form_credit_card_number").val().replaceAll(" ", ''),
        $("#monthValidate").val(),
        $("#yearValidate").val(),
        customerFirstName,
        customerLastName,
        $("#card_form_credit_card_security_code").val()
      );
      let tokenResponseHandler = function (data) {
        let token;
        if (data.errors) {
          //console.log("Erro salvando cartão: " + JSON.stringify(data.errors));
        } else {
          token = data.id;
          if (token) {
            $("#credit_card_token").val(token);
            $("#finalize_order_button_tokenize").prop('disabled', false)
          }
        }
      }
      TryploPay.createPaymentToken(cc, tokenResponseHandler);
      TryploPay.setTestMode(this.testModeValue);
    } else {
      $("#finalize_order_button_tokenize").prop('disabled', true)
    }
  }

  DomPagamentosV3Tokenize(event) {
    if ($(".credit_card_number_input").val() != "" &&
      $(".credit_card_titular_name_input").val() != "" &&
      $("#card_form_credit_card_security_code").val() > 2) {
      event.preventDefault();
      let public_key = this.tokenKeyValue;
      let data_card = {
        name: $(".credit_card_titular_name_input").val(),
        document: $("#card_form_credit_card_titular_document").val().replaceAll('.', '').replaceAll('-', ''),
        number: $("#card_form_credit_card_number").val().replaceAll(" ", ''),
        cvv: $("#card_form_credit_card_security_code").val(),
        month: $("#monthValidate").val(),
        year: $("#yearValidate").val()
      }

      let installments = parseInt($("#customer_orders_payment_installments").val());

      getTokenCard(public_key, data_card, installments, function (data) {
        $("#credit_card_token").val(JSON.stringify(data));
        $("#finalize_order_button_tokenize").prop('disabled', false)
      });

    } else {
      $("#finalize_order_button_tokenize").prop('disabled', true)
    }
  }

  MaxpayV2Tokenize(event) {
    $("#finalize_order_button_tokenize").prop('disabled', true)
    if (this.numberTarget.value != "" &&
      this.holderNameTarget.value != "" &&
      this.cvvCardTarget.value.length > 2) {

      setTimeout(async () => {

        HydraPay.setPublicKey(this.tokenKeyValue)

        var token = await HydraPay.encrypt({
          number: $("#card_form_credit_card_number").val().replaceAll(" ", ''),
          holderName: this.holderNameTarget.value,
          expMonth: $("#monthValidate").val(),
          expYear: $("#yearValidate").val(),
          cvv: $("#card_form_credit_card_security_code").val(),
        })

        $("#credit_card_token").val(token)
        if (token) {
          $("#finalize_order_button_tokenize").prop('disabled', false)
        }
      }, 300);
    }
  }
}

function disablePaymentKindSelect(paymentSelect) {
  paymentSelect.removeClass("payment_selected");
}

(() => {
  const $forms = $("fieldset form");
  const $submitButton = $forms.find('input[type="submit"][value="Continuar"]');

  $submitButton.on("click", (e) => {
    $forms.append(`
      <div class="spinner-border text-dark" role="status" style="width: 4rem; height: 4rem;">
        <span class="visually-hidden">Loading...</span>
      </div>
    `);

    return;
  });
})();